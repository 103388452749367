<template>
    <div>
        <app-layout>
            <Header :title="$t('medicine_groups_title').toUpper()" :is-filter="false"></Header>
            <HeaderMobile :title="$t('medicine_groups_title').toUpper()" :is-filter="false"></HeaderMobile>
            <div class="row data-form">
                <div class="col-12 col-md-12">
                    <div class="border px-5 pt-5 pb-2 mb-1 rounded">
                        <div class="row">
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <b-form-group :label="$t('status')">
                                            <medicine-groups-selectbox
                                                valueType="code"
                                                v-model="filterData.medicine_group_code">
                                            </medicine-groups-selectbox>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <b-form-group v-slot="{ ariaDescribedby }">
                                            <b-form-radio v-model="filterData.type" :aria-describedby="ariaDescribedby"
                                                          name="some-radios" value="1">{{ $t('new_status_radio') }}
                                            </b-form-radio>
                                            <b-form-radio v-model="filterData.type" :aria-describedby="ariaDescribedby"
                                                          name="some-radios" value="2">
                                                {{ $t('change_student_group_radio') }}
                                            </b-form-radio>
                                            <b-form-radio v-model="filterData.type" :aria-describedby="ariaDescribedby"
                                                          name="some-radios" value="3">
                                                {{ $t('remaining_students_radio') }}
                                            </b-form-radio>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <b-form-group :label="$t('student_numbers')">
                                    <lined-textarea v-model="studentNumbers"
                                                    :nowrap="false"
                                                    :disabled="false"
                                                    :styles="{ height: '15em', resize: 'both' }">
                                    </lined-textarea>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between">
                                <b-button v-if="checkPermission('studentprogram_medicinegroup')"
                                                      label="filter" variant="primary"
                                                      @click="getData"/>
                                <b-button v-if="checkPermission('studentprogram_updatemedicinegroup')"
                                                      label="update"
                                                      variant="primary"
                                                      @click="updateData"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12">
                    <b-table striped hover :items="students" :fields="fields">
                        <template #cell(input)="data">
                            <medicine-groups-selectbox
                                v-model="groups[data.item.id]"
                                valueType="code"></medicine-groups-selectbox>
                        </template>
                    </b-table>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import MedicineGroupsSelectbox from "@/components/interactive-fields/MedicineGroupsSelectbox";
import LinedTextarea from "@/components/elements/LinedTextarea";
import qs from "qs";
import StudentProgramService from "@/services/StudentProgramService";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";


export default {
    components: {
        HeaderMobile,
        Header,
        AppLayout,
        MedicineGroupsSelectbox,
        LinedTextarea,
    },
    data() {
        return {
            filterData: {
                medicine_group_code: null,
                student_numbers: [],
                type: null
            },
            studentNumbers: '',
            students: [],
            groups: [],
            fields: [
                {
                    key: 'student_number',
                    label: this.$t('student_number').toUpper()
                },
                {
                    key: 'name_surname',
                    label: this.$t('name_surname')
                },
                {
                    key: 'class',
                    label: this.$t('class')
                },
                {
                    key: 'medicine_group_code',
                    label: this.$t('status')
                },
                {
                    key: 'input',
                    label: ''
                },
                {
                    key: 'id',
                    label: '',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                }
            ]
        }
    },
    metaInfo() {
        return {
            title: this.$t("medicine_groups_title")
        }
    },
    methods: {
        getData() {
            this.students = []
            this.filterData.student_numbers = this.convertStudentNumbers()
            const config = {
                params: {
                    ...this.filterData
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }

            StudentProgramService.getMedicineGroups(config).then(response => {
                response.data.data.forEach(item => {
                    this.students.push({
                        student_number: item.student_number,
                        name_surname: item.name + ' ' + item.surname,
                        class: item.class,
                        medicine_group_code: item.medicine_group_code_name,
                        id: item.id
                    })
                })
            }).catch(e => {
                if (e.status == '406') { ///406 geldiği zaman değiştirilecek
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                } else if (e.status == '422') {
                    this.$toast.error(e.data.errors.type[0]);
                }
            })
        },

        convertStudentNumbers() {
            if (this.studentNumbers) {
                return this.studentNumbers.split(/\n/);
            }
            return [];
        },

        updateData() {
            if (this.groups.length > 0) {
                this.$swal({
                    title: this.$t('attention'),
                    text: this.$t('changes_will_be_saved'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                }).then(response => {
                    if (response.isConfirmed == true) {
                        let groups = []
                        this.groups.forEach((medicineGroupCode, id) => {
                            groups.push({id: id, medicine_group_code: medicineGroupCode})
                        })

                        let formData = {
                            groups: groups,
                            paramsSerializer: (groups) => qs.stringify(groups, {encode: false})
                        }

                        StudentProgramService.updateMedicineGroups(formData)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.getData()
                            })
                    }
                })
            } else {
                this.$toast.error(this.$t('empty_data_on_table'))
            }
        }
    },

    created() {

    }

};
</script>
